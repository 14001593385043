<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import LogsTimeline from '@/components/LogsTimeline';
import FilterBlock from '@/components/FilterBlock';
import { deletePropertiesWhichNoValue } from '@/util/helper';
import api from '@/services/api';

export default {
    name: 'LevelWinners',
    components: { FilterBlock, LogsTimeline, BRow, BCol, BButton, BFormGroup, BFormInput, vSelect },
    data: () => ({
        winners: {
            items: [],
            pagination: {
                current: null
            }
        },
        filterFields: {},
        filter: {},
        localLang: localStorage.getItem('lang'),
        requestPending: true
    }),
    computed: {
        computedLevels() {
            if (!this.filterFields.transactions) return;
            return this.filterFields.transactions.map((level) => {
                level.name = level.name[this.localLang];
                return level;
            });
        }
    },
    methods: {
        async getWinners() {
            deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            if (this.filter.phone) {
                this.filter.phone = this.filter.phone.replace(/\D/g, '');
            }
            await api.levels.getLevelWinners(this.filter).then(({ data }) => {
                if (data.pagination.current === 1) {
                    this.winners.items = data.items;
                } else {
                    this.winners.items = [...this.winners.items, ...data.items];
                }
                this.winners.pagination = data.pagination;
                this.requestPending = false;
            });
        },

        async getFilterFields() {
            this.filter.page = 1;
            const { data } = await api.subscribers.getFilterFields();
            this.filterFields = data;
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            await this.getWinners();
        },

        async filterWinners() {
            this.winners.items = [];
            this.filter.page = 1;
            await this.getWinners();
        },

        deSelect(val) {
            if (!val) {
                this.getWinners();
            }
        }
    },
    mounted() {
        Promise.all([
            this.getWinners()
            // this.getFilterFields()
        ]);
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="winners.items"
                    :pagination="winners.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ item.gifts.name[localLang] }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <router-link
                            :to="{
                                name: 'subscribers Single',
                                params: {
                                    id: item.user.id
                                }
                            }"
                            class="d-flex"
                        >
                            {{ item.user.phone }}
                        </router-link>
                        <div>
                            <small>{{ item.gifts.type }}</small>
                        </div>
                        <div>
                            <small>{{ item.gifts.level.name[localLang] }}</small>
                        </div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="col-lg-6 col-sm-5 col-12 order-1 order-sm-2">
                <filter-block
                    v-if="filterFields"
                    v-model:filter="filter"
                    :filter="filter"
                    @submit="filterWinners"
                >
                    <b-col class="mb-2" cols="12">
                        <h5>ID</h5>
                        <b-form-group>
                            <b-form-input v-model="filter.user_id" placeholder="Subscriber ID" />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('titles.phone_number') }}
                        </h5>
                        <b-form-group>
                            <b-form-input
                                v-model="filter.phone"
                                v-mask="'+998 9# ### ## ##'"
                                placeholder="+998 9# ### ## ##"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('titles.type') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.types"
                                :options="filterFields.types"
                                :searchable="false"
                                label="label"
                                multiple
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-2" cols="12">
                        <h5>
                            {{ $t('navigation.levels') }}
                        </h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.level_id"
                                :options="computedLevels"
                                :reduce="(level) => level.id"
                                :searchable="false"
                                label="name"
                                @input="deSelect"
                            />
                        </b-form-group>
                    </b-col>
                </filter-block>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
